import { animate, style, transition, trigger } from "@angular/animations";
import { AsyncPipe, DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { catchError, Observable, of, withLatestFrom } from "rxjs";
import { delay, filter, map, switchMap, take, tap } from "rxjs/operators";
import { SaveDialogComponent } from "@cg/olb/save-and-restore";
import { CustomerCaseFacade, DamageFacade, OlbFacade, ProcessFacade, YextFacade } from "@cg/olb/state";
import { SpinnerComponent } from "@cg/spinner";
import {
  AnalyticsEventContainerComponent,
  AnalyticsEventService,
  CleverreachService,
  OptimizelyFeEvent,
  TrackingService
} from "@cg/analytics";
import { BiProcessorService } from "@cg/bi-processor";
import { ServiceCenter, ServiceCenterAddress } from "@cg/core/interfaces";
import { UnifiedError } from "@cg/core/types";
import { errorToString, IS_SERVER_PLATFORM } from "@cg/core/utils";
import { environment } from "@cg/environments";
import { VAPsEventFlag, VAPsFailureEventData, VAPsSuccessEventData, VAPsTriggerEventData } from "@cg/olb/shared";
import {
  AdditionalProduct,
  CustomerCaseService,
  OverlayService,
  ProcessId,
  ProcessMetadata,
  ProgressbarComponent,
  SaveButtonComponent,
  ServiceCenterService
} from "@cg/shared";
import { OlbStepComponent } from "../olb-step/olb-step.component";

export const MESSAGE_ERROR_WIPER_NOT_ALLOWED_IN_REPAIR_CASE = "Add wiper is only allowed in replace case! ";
export const MESSAGE_ERROR_PRODUCT_TYPE_INVALID = "Event product type was not valid! ";

/**
 * the delay is for the animation to the next tile. it looks better when the
 * progressbar disappear when the next tile is nearly visible.
 */
const HIDE_PROCESS_BAR_DELAY = 500;

type OptimizelyBiProcessorTrackingEvent = CustomEvent<{
  message: string;
}>;

@Component({
  selector: "cg-olb-flow",
  templateUrl: "./olb-flow.component.html",
  animations: [
    trigger("hideAnimation", [
      transition(":leave", [
        style({ transform: "translateY(0)" }),
        animate("500ms", style({ transform: "translateY(-100px)" }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ProgressbarComponent, SaveButtonComponent, OlbStepComponent, SpinnerComponent]
})
export class OlbFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("olbFlow") public olbFlow: ElementRef;
  public destroyRef = inject(DestroyRef);
  public processPercentage$: Observable<number>;
  public processMetaData$ = this.processFacade.processMetaData$;
  public processBarVisible = true;

  private biProcessorTrackingSentMessageSet: Set<string> = new Set<string>();

  // eslint-disable-next-line max-params
  public constructor(
    private readonly processFacade: ProcessFacade,
    private readonly olbFacade: OlbFacade,
    private readonly overlayService: OverlayService,
    private readonly trackingService: TrackingService,
    private readonly biProcessorService: BiProcessorService,
    private readonly customerCaseFacade: CustomerCaseFacade,
    @Inject(IS_SERVER_PLATFORM) private readonly isServer: boolean,
    private readonly cdr: ChangeDetectorRef,
    private readonly analyticsEventService: AnalyticsEventService,
    private readonly cleverreachService: CleverreachService,
    private readonly yextFacade: YextFacade,
    private readonly serviceCenterService: ServiceCenterService,
    private readonly damageFacade: DamageFacade,
    private readonly customerCaseService: CustomerCaseService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  public get activeSaveAndRestore(): boolean {
    return environment.features.saveAndRestore;
  }

  public ngOnInit() {
    this.olbFacade.initOLB();

    this.processPercentage$ = this.processFacade.getProgressPercentage();

    this.processFacade.currentProcessId$
      .pipe(delay(HIDE_PROCESS_BAR_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((processId: ProcessId) => {
        this.processBarVisible =
          processId !== "channel-switch" &&
          processId !== "appointment-confirmation" &&
          processId !== "opportunity-funnel-success";
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit() {
    if (this.isServer) {
      return;
    }

    this.addEventHandler();
  }

  public ngOnDestroy(): void {
    if (this.isServer) {
      return;
    }

    this.removeEventHandler();
  }

  public trackByFn(_initialValueindex: number, item: ProcessMetadata): ProcessId {
    return item.id;
  }

  public onSaveClick() {
    this.trackingService.trackEvent({
      eventAction: "my-carglass",
      eventLabel: "save-progress-intention"
    });

    this.overlayService.open(SaveDialogComponent, null, { positionByBreakpoints: OverlayService.POSITION_M_CENTER });
  }

  private sendBiProcessorTracking(event: OptimizelyBiProcessorTrackingEvent) {
    const message = event.detail.message;
    if (this.biProcessorTrackingSentMessageSet.has(message)) {
      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.warn("BiProcessor tracking already sent");
      }
      return;
    }

    this.customerCaseFacade.customerCaseId$
      .pipe(
        filter((customerCaseId: string) => Boolean(customerCaseId)),
        take(1),
        switchMap((customerCaseId: string) =>
          this.biProcessorService.sendABVariations(message, customerCaseId).pipe(map(() => customerCaseId))
        ),
        tap(() => this.biProcessorTrackingSentMessageSet.add(message)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private sendCleverreachTracking(event: CustomEvent<{ email: string; groupId: string; confirmId: string }>): void {
    this.customerCaseFacade.customerCaseId$
      .pipe(
        map((customerCaseId: string) => ({
          customerCaseId: customerCaseId,
          email: event.detail.email,
          groupId: event.detail.groupId,
          confirmId: event.detail.confirmId
        })),
        switchMap((payload: { customerCaseId: string; email: string; groupId: string; confirmId: string }) =>
          this.cleverreachService.send(payload)
        )
      )
      .subscribe();
  }

  private getYextScInfos() {
    this.yextFacade.infos$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((infos: string) => !!infos),
        take(1)
      )
      .subscribe((infos: string) => {
        if (!/^[0-9]*$/.test(infos)) {
          if (isDevMode) {
            // eslint-disable-next-line no-console
            console.warn(`Currently only supports kst numbers but '${infos}' was given`);
          }
          return;
        }

        this.serviceCenterService
          .getServiceCenters([infos])
          .pipe(take(1))
          .subscribe((res: ServiceCenter[]) => {
            this.document
              .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
              .dispatchEvent(
                new CustomEvent<{ address: ServiceCenterAddress }>(OptimizelyFeEvent.GET_YEXT_SC_INFOS_SUCCESS, {
                  detail: { address: res?.[0].address }
                })
              );
          });
      });
  }

  private offerVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.customerCaseFacade.offerVAP(event.detail.product, event.detail.flags || []);
  }

  private addVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.toggleVAP(event, true).subscribe();
  }

  private removeVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.toggleVAP(event, false).subscribe();
  }

  private toggleVAP(event: CustomEvent<VAPsTriggerEventData>, add: boolean): Observable<boolean> {
    const flags: VAPsEventFlag[] = event.detail?.flags || [];
    const product: AdditionalProduct = event.detail?.product;

    return this.customerCaseFacade.customerCaseId$.pipe(
      withLatestFrom(this.damageFacade.requiredServiceIsReplace$),
      take(1),
      switchMap(([customerCaseId, isReplaceCase]: [string, boolean]): Observable<boolean> => {
        if (add && product === AdditionalProduct.WIPER && !isReplaceCase)
          return of(
            this.dispatchVAPsFailureEvent(
              customerCaseId,
              AdditionalProduct.WIPER,
              new Error(MESSAGE_ERROR_WIPER_NOT_ALLOWED_IN_REPAIR_CASE),
              add,
              flags
            )
          );

        const productType: AdditionalProduct = product;
        // This validation check could be removed, to allow unknown products
        if (!Object.values<string>(AdditionalProduct).includes(product)) {
          return of(
            this.dispatchVAPsFailureEvent(
              customerCaseId,
              productType,
              new Error(MESSAGE_ERROR_PRODUCT_TYPE_INVALID),
              add,
              flags
            )
          );
        }

        const serviceCallObservable = add
          ? this.customerCaseService.addProduct$(customerCaseId, productType)
          : this.customerCaseService.removeProduct$(customerCaseId, productType);

        return serviceCallObservable.pipe(
          map(() => this.dispatchVAPsSuccessEvent(customerCaseId, productType, add, flags)),
          catchError((error: UnifiedError) =>
            of(this.dispatchVAPsFailureEvent(customerCaseId, productType, error, add, flags))
          )
        );
      })
    );
  }

  private dispatchVAPsSuccessEvent(
    customerCaseId: string,
    product: AdditionalProduct,
    add: boolean,
    flags: VAPsEventFlag[]
  ): boolean {
    const flagsExtended = [...flags, VAPsEventFlag.TRIGGERED_BY_AB_TEST];
    if (add) {
      this.customerCaseFacade.addVAPSuccess(product, customerCaseId, true, flagsExtended);
    } else {
      this.customerCaseFacade.removeVAPSuccess(product, customerCaseId, flagsExtended);
    }

    return this.document
      .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
      .dispatchEvent(
        new CustomEvent<VAPsSuccessEventData>(
          add ? OptimizelyFeEvent.VAPS_ADD_SUCCESS : OptimizelyFeEvent.VAPS_REMOVE_SUCCESS,
          {
            detail: {
              product,
              customerCaseId,
              flags: flagsExtended
            }
          }
        )
      );
  }

  private dispatchVAPsFailureEvent(
    customerCaseId: string,
    product: AdditionalProduct,
    error: UnifiedError,
    add: boolean,
    flags: VAPsEventFlag[]
  ): boolean {
    const flagsExtended = [...flags, VAPsEventFlag.TRIGGERED_BY_AB_TEST];
    const errorMessageExtended =
      (add ? "ADD" : "REMOVE") + " of product " + product + " failed: " + errorToString(error);

    if (add) {
      this.customerCaseFacade.addVAPFailure(product, new Error(errorMessageExtended), customerCaseId, flagsExtended);
    } else {
      this.customerCaseFacade.removeVAPFailure(product, new Error(errorMessageExtended), customerCaseId, flagsExtended);
    }

    return this.document
      .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
      .dispatchEvent(
        new CustomEvent<VAPsFailureEventData>(
          add ? OptimizelyFeEvent.VAPS_ADD_FAILURE : OptimizelyFeEvent.VAPS_REMOVE_FAILURE,
          {
            detail: {
              product,
              customerCaseId,
              errorMessage: errorMessageExtended,
              flags: flagsExtended
            }
          }
        )
      );
  }

  private addEventHandler() {
    const eventService: AnalyticsEventService = this.analyticsEventService;
    eventService.addEventHandler(OptimizelyFeEvent.BI_PROCESSOR, this.sendBiProcessorTracking.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.CLEVERREACH, this.sendCleverreachTracking.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.GET_YEXT_SC_INFOS, this.getYextScInfos.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_OFFER, this.offerVAP.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_ADD, this.addVAP.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_REMOVE, this.removeVAP.bind(this));
  }

  private removeEventHandler() {
    const eventService: AnalyticsEventService = this.analyticsEventService;
    eventService.removeEventHandler(OptimizelyFeEvent.BI_PROCESSOR, this.sendBiProcessorTracking.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.CLEVERREACH, this.sendCleverreachTracking.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.GET_YEXT_SC_INFOS, this.getYextScInfos.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_OFFER, this.offerVAP.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_ADD, this.addVAP.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_REMOVE, this.removeVAP.bind(this));
  }
}
